<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row justify="center">
        <v-col xl="6" lg="8" md="10" sm="11" cols="12">
          <v-card elevation="2">
            <v-card-text class="text-center">
              <v-row
                ><v-col cols="12" class="text_field_col mb-5"
                  ><h2 style="color: #0090a4; width: 200px; float: left">
                    Sites
                  </h2>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        style="float: right"
                        v-bind="attrs"
                        v-on="on"
                        class="mb-n5 mr-4 mt-n2"
                        @click="dialog = true"
                        large
                        id="add_site_button"
                        color="primary"
                        :disabled="!(allowed_operations == 'WRITE')"
                        >mdi-plus-circle</v-icon
                      >
                    </template>
                    <span>Add Site</span>
                  </v-tooltip></v-col
                >
              </v-row>
              <v-card>
                <v-card-text>
                  <v-row
                    ><v-tabs center-active v-model="selected_site">
                      <v-tab
                        v-for="site in sites"
                        :key="site.id"
                        class="setting_tab"
                        >{{ site.name }}</v-tab
                      >
                      <!-- <v-tab class="setting_tab">Hardware</v-tab> -->
                    </v-tabs></v-row
                  >

                  <v-row class="mb-n5 mt-5">
                    <v-col
                      cols="12"
                      class="text_field_col"
                      v-if="sites && sites[selected_site]"
                    >
                      <v-row class=""
                        ><v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Name"
                            v-model="sites[selected_site].name"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-select
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Operation"
                            v-model="sites[selected_site].operation_type"
                            outlined
                            multiple
                            :items="[
                              { text: 'Beef', value: `'Beef'` },
                              { text: 'Bison', value: `'Bison'` },
                              { text: 'Chicken', value: `'Chicken'` },
                              { text: 'Cow/Calf', value: `'Cow/Calf'` },
                              { text: 'Dairy', value: `'Dairy'` },
                              { text: 'Goat', value: `'Goat'` },
                              { text: 'Feed Lot', value: `'Feed Lot'` },
                              { text: 'Swine', value: `'Swine'` },
                              { text: 'Other', value: `'Other'` },
                            ]"
                          ></v-select>
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Area"
                            v-model="sites[selected_site].area"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="text_field_col">
                          <v-row class="ml-0">
                            <div class="edit_item_cb">
                              <span class="cb_label">Community Pasture:</span>
                              <v-checkbox
                                :disabled="!(allowed_operations == 'WRITE')"
                                hide-details
                                class="mr-5 mt-0 pt-0"
                                v-model="
                                  sites[selected_site].community_pasture_used
                                "
                              ></v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider class="pb-4 mt-n3"></v-divider>
                      <v-row class="mb-n5"
                        ><v-col
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Latitude"
                            :rules="[validateIsFloat]"
                            v-model="sites[selected_site].coordinates_lat"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Longitude"
                            :rules="[validateIsFloat]"
                            v-model="sites[selected_site].coordinates_long"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-autocomplete
                            :readonly="!(allowed_operations == 'WRITE')"
                            :items="timezones"
                            label="Timezone"
                            v-model="sites[selected_site].timezone"
                            outlined
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-divider class="pb-4 mt-n3"></v-divider>
                      <v-row class="mb-n5"
                        ><v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Internet Provider"
                            v-model="sites[selected_site].internet_provider"
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col
                          xl="6"
                          lg="6"
                          md="6"
                          sm="12"
                          cols="12"
                          class="text_field_col"
                        >
                          <v-text-field
                            :readonly="!(allowed_operations == 'WRITE')"
                            label="Public IP"
                            :rules="[validateIP]"
                            v-model="sites[selected_site].public_ip"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row> </v-card-text
              ></v-card>
              <v-col cols="12" class="pt-0 pb-0 pr-0">
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      :disabled="
                        !(allowed_operations == 'WRITE') || !made_changes
                      "
                      @click="update_site()"
                      class="save_btn"
                      color="primary"
                      >save</v-btn
                    ></v-col
                  ></v-row
                >
              </v-col>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="900px" min-width="860px">
      <v-card>
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Add Site</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >

          <v-form v-model="create_form"
            ><v-row>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                  label="Name*"
                  v-model="new_site.name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-select
                  label="Operation"
                  v-model="new_site.operation_type"
                  outlined
                  multiple
                  :items="[
                    { text: 'Beef', value: `'Beef'` },
                    { text: 'Bison', value: `'Bison'` },
                    { text: 'Chicken', value: `'Chicken'` },
                    { text: 'Cow/Calf', value: `'Cow/Calf'` },
                    { text: 'Dairy', value: `'Dairy'` },
                    { text: 'Goat', value: `'Goat'` },
                    { text: 'Feed Lot', value: `'Feed Lot'` },
                    { text: 'Swine', value: `'Swine'` },
                    { text: 'Other', value: `'Other'` },
                  ]"
                ></v-select>
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                  label="Area"
                  v-model="new_site.area"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="text_field_col">
                <v-row class="ml-0">
                  <div class="edit_item_cb">
                    <span class="cb_label">Community Pasture:</span>
                    <v-checkbox
                      hide-details
                      class="mr-5 mt-0 pt-0"
                      v-model="new_site.community_pasture_used"
                    ></v-checkbox>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="pb-4 mt-n3"></v-divider>
            <v-row class="mb-n5"
              ><v-col
                xl="4"
                lg="4"
                md="4"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                  label="Latitude"
                  :rules="[validateIsFloat]"
                  v-model="new_site.coordinates_lat"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                  label="Longitude"
                  :rules="[validateIsFloat]"
                  v-model="new_site.coordinates_long"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                xl="4"
                lg="4"
                md="4"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-autocomplete
                  :items="timezones"
                  label="Timezone*"
                  v-model="new_site.timezone"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider class="pb-4 mt-n3"></v-divider>
            <v-row class="mb-n5"
              ><v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                  label="Internet Provider"
                  v-model="new_site.internet_provider"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                xl="6"
                lg="6"
                md="6"
                sm="12"
                cols="12"
                class="text_field_col"
              >
                <v-text-field
                id="public_ip_field"
                  label="Public IP"
                  :rules="[validateIP]"
                  v-model="new_site.public_ip"
                  outlined
                ></v-text-field>
              </v-col> </v-row
          ></v-form>
        </v-card-text>

        <v-card-actions class="pb-2 pt-2 mr-2">
          <v-row>
            <v-col class="text-right">
              <div>
                <v-btn color="gray" width="120" @click="close" class="mr-4">
                  Cancel
                </v-btn>
                <v-btn
                  style="float: right"
                  color="primary"
                  width="120"
                  :disabled="
                    !new_site.name || !new_site.timezone || !create_form
                  "
                  @click="create_new_record"
                >
                  Save
                </v-btn>
              </div></v-col
            >
          </v-row>
          <!-- <span class="error_span an_err" style="float: left">{{ error }}</span> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validateIsFloat, validateIP } from "@/utilities/ValidationRules";
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import { eventBus } from "../main.js";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "UserSettings",
  components: {
    navbar,
    sidenav,
    //events,
  },
  props: ["setting"],
  data() {
    return {
      selected_site: 0,
      create_form: false,

      made_changes: false,

      new_site: {},

      sites: false,

      dialog: false,
      timezones: [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Addis_Ababa",
        "Africa/Algiers",
        "Africa/Asmara",
        "Africa/Asmera",
        "Africa/Bamako",
        "Africa/Bangui",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Blantyre",
        "Africa/Brazzaville",
        "Africa/Bujumbura",
        "Africa/Cairo",
        "Africa/Casablanca",
        "Africa/Ceuta",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Douala",
        "Africa/El_Aaiun",
        "Africa/Freetown",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Kigali",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Lome",
        "Africa/Luanda",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Malabo",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Africa/Mogadishu",
        "Africa/Monrovia",
        "Africa/Nairobi",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Porto-Novo",
        "Africa/Sao_Tome",
        "Africa/Timbuktu",
        "Africa/Tripoli",
        "Africa/Tunis",
        "Africa/Windhoek",
        "America/Adak",
        "America/Anchorage",
        "America/Anguilla",
        "America/Antigua",
        "America/Araguaina",
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/ComodRivadavia",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Aruba",
        "America/Asuncion",
        "America/Atikokan",
        "America/Atka",
        "America/Bahia",
        "America/Bahia_Banderas",
        "America/Barbados",
        "America/Belem",
        "America/Belize",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Bogota",
        "America/Boise",
        "America/Buenos_Aires",
        "America/Cambridge_Bay",
        "America/Campo_Grande",
        "America/Cancun",
        "America/Caracas",
        "America/Catamarca",
        "America/Cayenne",
        "America/Cayman",
        "America/Chicago",
        "America/Chihuahua",
        "America/Coral_Harbour",
        "America/Cordoba",
        "America/Costa_Rica",
        "America/Creston",
        "America/Cuiaba",
        "America/Curacao",
        "America/Danmarkshavn",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Denver",
        "America/Detroit",
        "America/Dominica",
        "America/Edmonton",
        "America/Eirunepe",
        "America/El_Salvador",
        "America/Ensenada",
        "America/Fort_Nelson",
        "America/Fort_Wayne",
        "America/Fortaleza",
        "America/Glace_Bay",
        "America/Godthab",
        "America/Goose_Bay",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guatemala",
        "America/Guayaquil",
        "America/Guyana",
        "America/Halifax",
        "America/Havana",
        "America/Hermosillo",
        "America/Indiana/Indianapolis",
        "America/Indiana/Knox",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Tell_City",
        "America/Indiana/Vevay",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Indianapolis",
        "America/Inuvik",
        "America/Iqaluit",
        "America/Jamaica",
        "America/Jujuy",
        "America/Juneau",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        " America/Knox_IN",
        " America/Kralendijk",
        " America/La_Paz",
        " America/Lima",
        " America/Los_Angeles",
        " America/Louisville",
        " America/Lower_Princes",
        " America/Maceio",
        " America/Managua",
        " America/Manaus",
        " America/Marigot",
        " America/Martinique",
        " America/Matamoros",
        " America/Mazatlan",
        " America/Mendoza",
        " America/Menominee",
        " America/Merida",
        " America/Metlakatla",
        " America/Mexico_City",
        " America/Miquelon",
        " America/Moncton",
        " America/Monterrey",
        " America/Montevideo",
        "America/Montreal",
        "America/Montserrat",
        "America/Nassau",
        "America/New_York",
        "America/Nipigon",
        "America/Nome",
        "America/Noronha",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Ojinaga",
        "America/Panama",
        "America/Pangnirtung",
        "America/Paramaribo",
        "America/Phoenix",
        "America/Port-au-Prince",
        "America/Port_of_Spain",
        "America/Porto_Acre",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Punta_Arenas",
        "America/Rainy_River",
        "America/Rankin_Inlet",
        "America/Recife",
        "America/Regina",
        "America/Resolute",
        "America/Rio_Branco",
        "America/Rosario",
        "America/Santa_Isabel",
        "America/Santarem",
        "America/Santiago",
        "America/Santo_Domingo",
        "America/Sao_Paulo",
        "America/Scoresbysund",
        "America/Shiprock",
        "America/Sitka",
        "America/St_Barthelemy",
        "America/St_Johns",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Swift_Current",
        "America/Tegucigalpa",
        "America/Thule",
        "America/Thunder_Bay",
        "America/Tijuana",
        "America/Toronto",
        "America/Tortola",
        "America/Vancouver",
        "America/Virgin",
        "America/Whitehorse",
        "America/Winnipeg",
        "America/Yakutat",
        "America/Yellowknife",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Macquarie",
        "Antarctica/Mawson",
        "Antarctica/McMurdo",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/South_Pole",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
        "Arctic/Longyearbyen",
        "Asia/Aden",
        "Asia/Almaty",
        "Asia/Amman",
        "Asia/Anadyr",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Ashkhabad",
        "Asia/Atyrau",
        "Asia/Baghdad",
        "Asia/Bahrain",
        "Asia/Baku",
        "Asia/Bangkok",
        "Asia/Barnaul",
        "Asia/Beirut",
        "Asia/Bishkek",
        "Asia/Brunei",
        "Asia/Calcutta",
        "Asia/Chita",
        "Asia/Choibalsan",
        "Asia/Chongqing",
        "Asia/Chungking",
        "Asia/Colombo",
        "Asia/Dacca",
        "Asia/Damascus",
        "Asia/Dhaka",
        "Asia/Dili",
        "Asia/Dubai",
        "Asia/Dushanbe",
        "Asia/Famagusta",
        "Asia/Gaza",
        "Asia/Harbin",
        "Asia/Hebron",
        "Asia/Ho_Chi_Minh",
        "Asia/Hong_Kong",
        "Asia/Hovd",
        "Asia/Irkutsk",
        "Asia/Istanbul",
        "Asia/Jakarta",
        "Asia/Jayapura",
        "Asia/Jerusalem",
        "Asia/Kabul",
        "Asia/Kamchatka",
        "Asia/Karachi",
        "Asia/Kashgar",
        "Asia/Kathmandu",
        "Asia/Katmandu",
        "Asia/Khandyga",
        "Asia/Kolkata",
        "Asia/Krasnoyarsk",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Kuwait",
        "Asia/Macao",
        "Asia/Macau",
        "Asia/Magadan",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Muscat",
        "Asia/Nicosia",
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk",
        "Asia/Oral",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Pyongyang",
        "Asia/Qatar",
        "Asia/Qyzylorda",
        "Asia/Rangoon",
        "Asia/Riyadh",
        "Asia/Saigon",
        "Asia/Sakhalin",
        "Asia/Samarkand",
        "Asia/Seoul",
        "Asia/Shanghai",
        "Asia/Singapore",
        "Asia/Srednekolymsk",
        "Asia/Taipei",
        "Asia/Tashkent",
        "Asia/Tbilisi",
        "Asia/Tehran",
        "Asia/Tel_Aviv",
        "Asia/Thimbu",
        "Asia/Thimphu",
        "Asia/Tokyo",
        "Asia/Tomsk",
        "Asia/Ujung_Pandang",
        "Asia/Ulaanbaatar",
        "Asia/Ulan_Bator",
        "Asia/Urumqi",
        "Asia/Ust-Nera",
        "Asia/Vientiane",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yangon",
        "Asia/Yekaterinburg",
        "Asia/Yerevan",
        "Atlantic/Azores",
        "Atlantic/Bermuda",
        "Atlantic/Canary",
        "Atlantic/Cape_Verde",
        "Atlantic/Faeroe",
        "Atlantic/Faroe",
        "Atlantic/Jan_Mayen",
        "Atlantic/Madeira",
        "Atlantic/Reykjavik",
        "Atlantic/South_Georgia",
        "Atlantic/St_Helena",
        "Atlantic/Stanley",
        "Australia/ACT",
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Broken_Hill",
        "Australia/Canberra",
        "Australia/Currie",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/LHI",
        "Australia/Lindeman",
        "Australia/Lord_Howe",
        "Australia/Melbourne",
        "Australia/NSW",
        "Australia/North",
        "Australia/Perth",
        "Australia/Queensland",
        "Australia/South",
        "Australia/Sydney",
        "Australia/Tasmania",
        "Australia/Victoria",
        "Australia/West",
        "Australia/Yancowinna",
        "Brazil/Acre",
        "Brazil/DeNoronha",
        "Brazil/East",
        "Brazil/West",
        "CET",
        "CST6CDT",
        "Canada/Atlantic",
        "Canada/Central",
        "Canada/Eastern",
        "Canada/Mountain",
        "Canada/Newfoundland",
        "Canada/Pacific",
        "Canada/Saskatchewan",
        "Canada/Yukon",
        "Chile/Continental",
        "Chile/EasterIsland",
        "Cuba",
        "EET",
        "EST",
        "EST5EDT",
        "Egypt",
        "Eire",
        "Etc/GMT",
        "Etc/GMT+0",
        "Etc/GMT+1",
        "Etc/GMT+10",
        "Etc/GMT+11",
        "Etc/GMT+12",
        "Etc/GMT+2",
        "Etc/GMT+3",
        "Etc/GMT+4",
        "Etc/GMT+5",
        "Etc/GMT+6",
        "Etc/GMT+7",
        "Etc/GMT+8",
        "Etc/GMT+9",
        "Etc/GMT-0",
        "Etc/GMT-1",
        "Etc/GMT-10",
        "Etc/GMT-11",
        "Etc/GMT-12",
        "Etc/GMT-13",
        "Etc/GMT-14",
        "Etc/GMT-2",
        "Etc/GMT-3",
        "Etc/GMT-4",
        "Etc/GMT-5",
        "Etc/GMT-6",
        "Etc/GMT-7",
        "Etc/GMT-8",
        "Etc/GMT-9",
        "Etc/GMT0",
        "Etc/Greenwich",
        "Etc/UCT",
        "Etc/UTC",
        "Etc/Universal",
        "Etc/Zulu",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Astrakhan",
        "Europe/Athens",
        "Europe/Belfast",
        "Europe/Belgrade",
        "Europe/Berlin",
        "Europe/Bratislava",
        "Europe/Brussels",
        "Europe/Bucharest",
        "Europe/Budapest",
        "Europe/Busingen",
        "Europe/Chisinau",
        "Europe/Copenhagen",
        "Europe/Dublin",
        "Europe/Gibraltar",
        "Europe/Guernsey",
        "Europe/Helsinki",
        "Europe/Isle_of_Man",
        "Europe/Istanbul",
        "Europe/Jersey",
        "Europe/Kaliningrad",
        "Europe/Kiev",
        "Europe/Kirov",
        "Europe/Lisbon",
        "Europe/Ljubljana",
        "Europe/London",
        "Europe/Luxembourg",
        "Europe/Madrid",
        "Europe/Malta",
        "Europe/Mariehamn",
        "Europe/Minsk",
        "Europe/Monaco",
        "Europe/Moscow",
        "Europe/Nicosia",
        "Europe/Oslo",
        "Europe/Paris",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Riga",
        "Europe/Rome",
        "Europe/Samara",
        "Europe/San_Marino",
        "Europe/Sarajevo",
        "Europe/Saratov",
        "Europe/Simferopol",
        "Europe/Skopje",
        "Europe/Sofia",
        "Europe/Stockholm",
        "Europe/Tallinn",
        "Europe/Tirane",
        "Europe/Tiraspol",
        "Europe/Ulyanovsk",
        "Europe/Uzhgorod",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Vilnius",
        "Europe/Volgograd",
        "Europe/Warsaw",
        "Europe/Zagreb",
        "Europe/Zaporozhye",
        "Europe/Zurich",
        "GB",
        "GB-Eire",
        "GMT",
        "GMT+0",
        "GMT-0",
        "GMT0",
        "Greenwich",
        "HST",
        "Hongkong",
        "Iceland",
        "Indian/Antananarivo",
        "Indian/Chagos",
        "Indian/Christmas",
        "Indian/Cocos",
        "Indian/Comoro",
        "Indian/Kerguelen",
        "Indian/Mahe",
        "Indian/Maldives",
        "Indian/Mauritius",
        "Indian/Mayotte",
        "Indian/Reunion",
        "Iran",
        "Israel",
        "Jamaica",
        "Japan",
        "Kwajalein",
        "Libya",
        "MET",
        "MST",
        "MST7MDT",
        "Mexico/BajaNorte",
        "Mexico/BajaSur",
        "Mexico/General",
        "NZ",
        "NZ-CHAT",
        "Navajo",
        "PRC",
        "PST8PDT",
        "Pacific/Apia",
        "Pacific/Auckland",
        "Pacific/Bougainville",
        "Pacific/Chatham",
        "Pacific/Chuuk",
        "Pacific/Easter",
        "Pacific/Efate",
        "Pacific/Enderbury",
        "Pacific/Fakaofo",
        "Pacific/Fiji",
        "Pacific/Funafuti",
        "Pacific/Galapagos",
        "Pacific/Gambier",
        "Pacific/Guadalcanal",
        "Pacific/Guam",
        "Pacific/Honolulu",
        "Pacific/Johnston",
        "Pacific/Kiritimati",
        "Pacific/Kosrae",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Marquesas",
        "Pacific/Midway",
        "Pacific/Nauru",
        "Pacific/Niue",
        "Pacific/Norfolk",
        "Pacific/Noumea",
        "Pacific/Pago_Pago",
        "Pacific/Palau",
        "Pacific/Pitcairn",
        "Pacific/Pohnpei",
        "Pacific/Ponape",
        "Pacific/Port_Moresby",
        "Pacific/Rarotonga",
        "Pacific/Saipan",
        "Pacific/Samoa",
        "Pacific/Tahiti",
        "Pacific/Tarawa",
        "Pacific/Tongatapu",
        "Pacific/Truk",
        "Pacific/Wake",
        "Pacific/Wallis",
        "Pacific/Yap",
        "Poland",
        "Portugal",
        "ROC",
        "ROK",
        "Singapore",
        "Turkey",
        "UCT",
        "US/Alaska",
        "US/Aleutian",
        "US/Arizona",
        "US/Central",
        "US/East-Indiana",
        "US/Eastern",
        "US/Hawaii",
        "US/Indiana-Starke",
        "US/Michigan",
        "US/Mountain",
        "US/Pacific",
        "US/Pacific-New",
        "US/Samoa",
        "UTC",
        "Universal",
        "W-SU",
        "WET",
        "Zulu",
      ],
    };
  },
  computed: {
    sites_initial() {
      return this.$store.getters.getSites;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
    changed_sites() {
      var site_indexes = [];
      for (const i in this.sites_initial) {
        if (
          this.sites[i] &&
          this.object_equal(this.sites_initial[i], this.sites[i])
        ) {
          site_indexes.push(i);
        }
      }
      return site_indexes;
    },
  },
  methods: {
    validateIsFloat,
    validateIP,
    async create_new_record() {
      this.new_site.customer_id = this.$store.getters.getUser.username;
      this.new_site.id = uuidv4();

      const response = await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.insert_site_one,
          variables: {
            object1: this.new_site,
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      if (response.errors || response.data.errors) {
        eventBus.$emit("add_button_alert", {
          msg: `Error creating the camera`,
          err: true,
        });
        return false;
      }

      this.dialog = false;
      this.$store.dispatch("DDB_GET_SITES");
      eventBus.$emit("add_button_alert", {
        msg: `Successfully created the site`,
        err: false,
      });
    },

    object_equal(original, updated) {
      var diff_object = {};
      for (const i in original) {
        if (i == "operation_type") {
          if (JSON.stringify(original[i]) !== JSON.stringify(updated[i])) {
            diff_object[i] = updated[i];
          }
        } else if (original[i] !== updated[i]) {
          diff_object[i] = updated[i];
        }
      }
      if (JSON.stringify(diff_object) === "{}") {
        return false;
      }
      return diff_object;
    },

    async execute_query(index) {
      const response = await axios.post(
        this.$store.getters.getHasuraParamsWrite.url,
        {
          query: hasura_mutations.update_site_by_pk,
          variables: {
            pk_columns: { id: this.sites[index].id },
            _set: this.object_equal(
              this.sites_initial[index],
              this.sites[index]
            ),
          },
        },
        {
          headers: this.$store.getters.getHasuraParamsWrite.headers,
        }
      );
      if (response.errors || response.data.errors) {
        throw new Error("GraphQL error: " + response.errors[0].message);
      }
      return response.data;
    },
    close() {
      this.dialog = false;
    },

    async update_site() {
      let all_queries = [];

      for (const i in this.changed_sites) {
        all_queries.push(this.execute_query(i));
      }

      const results = await Promise.allSettled(all_queries);

      const hasError = results.some((result) => result.status === "rejected");

      if (hasError) {
        eventBus.$emit("add_button_alert", {
          msg: `Error updating sites`,
          err: true,
        });
        if (this.sites_initial) {
          this.sites = JSON.parse(JSON.stringify(this.$store.getters.getSites));
        }
        this.made_changes = false;
      } else {
        this.$store.dispatch("DDB_GET_SITES");
        eventBus.$emit("add_button_alert", {
          msg: `Successfully updated sites`,
          err: false,
        });
      }
    },
  },
  watch: {
    changed_sites() {
      if (this.changed_sites && this.changed_sites.length) {
        this.made_changes = true;
      } else {
        this.made_changes = false;
      }
    },
    sites_initial() {
      if (this.sites_initial) {
        this.sites = JSON.parse(JSON.stringify(this.$store.getters.getSites));
      }
    },
    dialog() {
      this.new_site = {};
    },
  },
  mounted() {},
};
</script>

<style lang="css">
.non_placed_hardware {
  cursor: initial;
}

.map_item_content_false {
  cursor: pointer !important;
}
.map_item_content_true {
  color: gray;
}

.map_side_bar .v-list-item {
  cursor: move;
}

.map_side_bar {
  overflow-y: scroll;
}
.settings_card_title {
  background-color: #ff9900;
}
.text_field_col {
  text-align: left;
}
.text_field_title {
  font-weight: bold;
}
.setting_tab {
  font-weight: bold;
  font-size: 14px;
}
.val_error {
  color: red;
}
</style>
